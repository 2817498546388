
































































import {
    computed,
    defineComponent,
    reactive,
    toRefs,
    watch,
} from "@vue/composition-api"
import UserChips from "@/components/UserChips.vue"
import KeywordInput from "@/components/KeywordInput.vue"
import { page, itemsPerPage, keyword } from "@/services/QueryParmsService"
import { currentRoute } from "@/services/Router"
import {
    getMissionTemplates,
    exportMissionTemplates,
    MissionTemplateViewModel,
    deleteMissionTemplate,
} from "@/services/MissionTemplatesService"
import { missionTypeFormatter } from "@/services/MissionsService"
import { timeFormatter } from "@/utilities/Formatter"
import { userCache } from "@/services/UsersClient"
import {
    ButtonResult,
    DialogButtonType,
    errorDialog,
    successDialog,
} from "@/services/DialogService"

export default defineComponent({
    name: "MissionTemplate",
    components: {
        UserChips,
        KeywordInput,
    },
    setup() {
        const state = reactive({
            items: [] as MissionTemplateViewModel[],
            total: 0,
            loading: false,
        })

        const totalPages = computed(() =>
            Math.ceil(state.total / itemsPerPage.value)
        )

        async function loadData() {
            state.loading = true
            const { total, items } = await getMissionTemplates({
                skip: itemsPerPage.value * (page.value - 1),
                take: itemsPerPage.value,
                keyword: keyword.value,
            })
            state.items = items.map((item) => {
                const result: MissionTemplateViewModel = item
                result.managers = undefined
                result.assignees = undefined
                result.cooperators = undefined
                return result
            })
            state.total = total
            await userCache.updateByIds(
                items.flatMap((item) =>
                    item.managerIds
                        .concat(item.assigneeIds)
                        .concat(item.cooperatorIds)
                )
            )
            state.items.forEach((item) => {
                item.managers = item.managerIds.map(
                    (id) => userCache.get(id) ?? null
                )
                item.assignees = item.assigneeIds.map(
                    (id) => userCache.get(id) ?? null
                )
                item.cooperators = item.cooperatorIds.map(
                    (id) => userCache.get(id) ?? null
                )
            })
            state.loading = false
        }

        watch(
            () => currentRoute.value!.query,
            () => {
                loadData()
            }
        )

        loadData()

        async function remove(id: string) {
            const { button: confirmDelete } = await errorDialog(
                `確認要刪除任務範本?`,
                undefined,
                DialogButtonType.ConfirmCancel
            )
            if (confirmDelete === ButtonResult.Cancel) return
            try {
                await deleteMissionTemplate(id)
                successDialog("成功刪除")
                loadData()
            } catch (error) {
                console.error(error)
                errorDialog("刪除失敗，請聯絡系統維護人員")
            }
        }

        function exportByQuery() {
            return exportMissionTemplates({
                keyword: keyword.value,
            })
        }

        return {
            ...toRefs(state),

            page,
            itemsPerPage,
            totalPages,
            loadData,
            timeFormatter,
            missionTypeFormatter,
            exportByQuery,
            remove,
        }
    },
})
